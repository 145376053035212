import classNames from 'classnames';

import { BlockQuote } from '@components/blockQuote/BlockQuote';
import { CallToAction } from '@components/callToAction/CallToAction';
import { CarouselSection } from '@components/carouselSection/CarouselSection';
import { ContactForm } from '@components/contactForm/ContactForm';
import { CoverSection } from '@components/coverSection/CoverSection';
import { EventCollection } from '@components/eventCollection/EventCollection';
import { FaqSection } from '@components/faqSection/FaqSection';
import { HeaderSection } from '@components/headerSection/HeaderSection';
import { HomePageTeaserSection } from '@components/homePageTeaserSection/HomePageTeaserSection';
import { ImageSection } from '@components/imageSection/ImageSection';
import { InfoBlockSection } from '@components/infoBlockSection/InfoBlockSection';
import { InfoSection } from '@components/infoSection/InfoSection';
import { JobList } from '@components/jobList/JobList';
import { JustAnnouncedEventSection } from '@components/justAnnouncedEventSection/JustAnnouncedEventSection';
import { LogoMarquee } from '@components/logoMarquee/LogoMarquee';
import { MissionStatement } from '@components/missionStatement/MissionStatement';
import { NewsletterSection } from '@components/newsletterSection/NewsletterSection';
import { PageHeader } from '@components/pageHeader/PageHeader';
import { PlaylistSection } from '@components/playlistSection/PlaylistSection';
import { PressAwardSection } from '@components/pressAwardSection/PressAwardSection';
import { RentalsForm } from '@components/rentalsForm/RentalsForm';
import { Showcase } from '@components/showcase/Showcase';
import { SpaceList } from '@components/spaceList/SpaceList';
import { TestimonialSection } from '@components/testimonialSection/TestimonialSection';
import { TextSection } from '@components/textSection/TextSection';
import { VideoSection } from '@components/videoSection/VideoSection';

import {
	BlockQuoteRecord,
	CallToActionRecord,
	CarouselSectionRecord,
	ContactFormRecord,
	CoverSectionRecord,
	DiceEvent,
	EventCollectionCustomRecord,
	EventCollectionFilterRecord,
	FaqSectionRecord,
	HeaderSectionRecord,
	HomePageTeaserSectionRecord,
	ImageSectionRecord,
	InfoBlockSectionRecord,
	InfoSectionRecord,
	JobListRecord,
	LogoSectionRecord,
	MissionStatementRecord,
	NewsletterSectionRecord,
	PageHeaderRecord,
	PageModule,
	PageRecord,
	PlaylistSectionRecord,
	PressAwardSectionRecord,
	ProgrammeLabels,
	RentalsFormRecord,
	ShowcaseRecord,
	SpaceListRecord,
	TestimonialSectionRecord,
	TextSectionRecord,
	UpcomingEventSectionRecord,
	VideoSectionRecord,
} from '@interfaces';

import styles from './page-detail.module.scss';

const renderModule = (
	module: PageModule,
	programmeLabels: ProgrammeLabels,
	serverNow: number,
	justAnnouncedEvents?: DiceEvent[] | null,
) => {
	const { id: key } = module;
	const typeNameKey = '__typename';

	switch (module[typeNameKey]) {
		case 'BlockQuoteRecord':
			return <BlockQuote key={key} {...(module as BlockQuoteRecord)} />;
		case 'PageHeaderRecord':
			return <PageHeader key={key} {...(module as PageHeaderRecord)} />;
		case 'FaqSectionRecord':
			return <FaqSection key={key} {...(module as FaqSectionRecord)} />;
		case 'ContactFormRecord':
			return <ContactForm key={key} {...(module as ContactFormRecord)} />;
		case 'ShowcaseRecord':
			return <Showcase key={key} {...(module as ShowcaseRecord)} />;
		case 'SpaceListRecord':
			return <SpaceList key={key} {...(module as SpaceListRecord)} />;
		case 'InfoSectionRecord':
			return <InfoSection key={key} {...(module as InfoSectionRecord)} />;
		case 'InfoBlockSectionRecord':
			return (
				<InfoBlockSection key={key} {...(module as InfoBlockSectionRecord)} />
			);
		case 'ImageSectionRecord':
			return <ImageSection key={key} {...(module as ImageSectionRecord)} />;
		case 'VideoSectionRecord':
			return <VideoSection key={key} {...(module as VideoSectionRecord)} />;
		case 'CarouselSectionRecord':
			return (
				<CarouselSection key={key} {...(module as CarouselSectionRecord)} />
			);
		case 'CallToActionRecord':
			return <CallToAction key={key} {...(module as CallToActionRecord)} />;
		case 'JobListRecord':
			return <JobList key={key} {...(module as JobListRecord)} />;
		case 'MissionStatementRecord':
			return (
				<MissionStatement key={key} {...(module as MissionStatementRecord)} />
			);
		case 'TestimonialSectionRecord':
			return (
				<TestimonialSection
					key={key}
					{...(module as TestimonialSectionRecord)}
				/>
			);
		case 'LogoSectionRecord':
			return <LogoMarquee key={key} {...(module as LogoSectionRecord)} />;
		case 'PressAwardSectionRecord':
			return (
				<PressAwardSection key={key} {...(module as PressAwardSectionRecord)} />
			);
		case 'RentalsFormRecord':
			return <RentalsForm key={key} {...(module as RentalsFormRecord)} />;
		case 'EventCollectionFilterRecord':
			return (
				<EventCollection
					key={key}
					{...(module as EventCollectionFilterRecord)}
					programmeLabels={programmeLabels}
					serverNow={serverNow}
				/>
			);
		case 'EventCollectionCustomRecord':
			return (
				<EventCollection
					key={key}
					{...(module as EventCollectionCustomRecord)}
					programmeLabels={programmeLabels}
					serverNow={serverNow}
				/>
			);
		case 'CoverSectionRecord':
			return <CoverSection key={key} {...(module as CoverSectionRecord)} />;
		case 'UpcomingEventSectionRecord':
			if (justAnnouncedEvents) {
				return (
					<JustAnnouncedEventSection
						events={justAnnouncedEvents}
						key={key}
						{...(module as UpcomingEventSectionRecord)}
					/>
				);
			}

			return null;
		case 'HomePageTeaserSectionRecord':
			return (
				<HomePageTeaserSection
					key={key}
					{...(module as HomePageTeaserSectionRecord)}
				/>
			);
		case 'NewsletterSectionRecord':
			return (
				<NewsletterSection
					isCompact={false}
					key={key}
					{...(module as NewsletterSectionRecord)}
					location="website page"
				/>
			);
		case 'PlaylistSectionRecord':
			return (
				<PlaylistSection key={key} {...(module as PlaylistSectionRecord)} />
			);
		case 'HeaderSectionRecord':
			return <HeaderSection key={key} {...(module as HeaderSectionRecord)} />;
		case 'TextSectionRecord':
			return <TextSection key={key} {...(module as TextSectionRecord)} />;
		default:
			return null;
	}
};

interface PageDetailProps extends PageRecord {
	programmeLabels: ProgrammeLabels;
	serverNow: number;
	isHomePage?: boolean;
	justAnnouncedEvents?: DiceEvent[] | null;
}

export const PageDetail = ({
	title,
	modules,
	programmeLabels,
	serverNow,
	isHomePage,
	justAnnouncedEvents,
}: PageDetailProps) => {
	return (
		<article
			className={classNames(styles['page-detail'], {
				[styles['is-home-page']]: isHomePage,
			})}
		>
			<h1 className="sr-only">{title}</h1>
			{modules.map((module) => {
				return renderModule(
					module,
					programmeLabels,
					serverNow,
					justAnnouncedEvents,
				);
			})}
		</article>
	);
};
